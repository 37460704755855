import React from "react";
import MenuItem from "../components/menuItem";

import sosluDurumImage from '../assets/images/menu/soslu_durum.jpg';
import tamEkmekImage from '../assets/images/menu/tam_ekmek.jpg';
import yarimEkmekImage from '../assets/images/menu/yarim_ekmek.jpg';
import zurnaImage from '../assets/images/menu/zurna_durum.jpeg';
import kutuCocaCola from '../assets/images/menu/coca_cola.jpg';
import iceTeaSeftali from '../assets/images/menu/ice_tea_seftali.jfif';
import pepsi from '../assets/images/menu/pepsi.jfif';
import soda from '../assets/images/menu/soda.jpg';
import su from '../assets/images/menu/su.webp';
import ayran from '../assets/images/menu/ayran.webp';

const Menu = () => {
    const yiyecekler = [
        {
            id: 1,
            title: "Soslu Tavuk Dürüm",
            imageUrl: sosluDurumImage,
            description: "Özel soslarla marine edilmiş tavuk, taze sebzeler ve yumuşacık lavaşla buluşuyor. Her lokmada unutulmaz bir lezzet! Afiyet olsun!",
            type: 0
        },
        {
            id: 2,
            title: "Zurna Tavuk Dürüm",
            imageUrl: zurnaImage,
            description: "Bol malzemeli, dev zurna dürüm! Izgarada pişirilmiş tavuk, taze sebzeler ve özel soslarla dolu bu dürüm, doyurucu ve lezzet dolu bir deneyim sunar. Afiyet olsun!",
            type: 0
        },
        {
            id: 3,
            title: "Yarım Ekmek Tavuk Döner",
            imageUrl: yarimEkmekImage,
            description: "Taze ve çıtır yarım ekmek içinde, lezzetli tavuk döner ve taptaze sebzeler. </br>Özel sosumuzla taçlandırılan bu doyurucu lezzet, her lokmada sizi mutlu edecek. Afiyet olsun!",
            type: 0
        },
        {
            id: 4,
            title: "Tam Ekmek Tavuk Döner",
            imageUrl: tamEkmekImage,
            description: "Dolu dolu lezzet! Tam ekmek arasında, bolca tavuk döner, taze sebzeler ve özel sosumuzla hazırlanmış bu enfes döner, doyurucu ve tatmin edici bir yemek deneyimi sunar. Afiyet olsun!",
            type: 0
        },
    ]

    const icecekler = [
        {
            id: 1,
            title: "Coca Cola",
            imageUrl: kutuCocaCola,
            type: 1
        },
        {
            id: 2,
            title: "Ice Tea Şeftali",
            imageUrl: iceTeaSeftali,
            type: 1,
        },
        {
            id: 3,
            title: "Pepsi",
            imageUrl: pepsi,
            type: 1,

        },
        {
            id: 4,
            title: "Soda",
            imageUrl: soda,
            tpye: 1,
        },
        {
            id: 5,
            title: "Su",
            imageUrl: su,
            type: 1,
        },
        {
            id: 6,
            title: "Ayran",
            imageUrl: ayran,
            type: 1
        }
    ]

    return (
        <div className="w-full h-full p-10 !pt-28 flex flex-col gap-10 sm:gap-16 md:gap-20">
            <div className="flex flex-col gap-4 sm:gap-6">
                <h2 className="font-bold text-lg ">Yiyecekler</h2>
                <div className="gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    {yiyecekler.map(({ title, imageUrl, id, description }) => (
                        <MenuItem key={id} title={title} imageUrl={imageUrl} description={description} />
                    ))}
                </div>
            </div>

            <div className="flex flex-col gap-4 sm:gap-6">
                <h2 className="font-bold text-lg ">İçecekler</h2>
                <div className="gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    {icecekler.map(({ title, imageUrl, id, description, type }) => (
                        <MenuItem type={type} key={id} title={title} imageUrl={imageUrl} description={description} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Menu;