import React from 'react';
import Navbar from './navbar';
import Contact from './contact'
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <main className="flex-grow">
                <Outlet />
            </main>
            <Contact/>
        </div>
    );
};

export default Layout;