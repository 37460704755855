import React from 'react';

const MenuItem = ({ title, imageUrl, description, type }) => {
    const backgroundSize = type === 1 ? 'contain' : null;
    return (
        <div className={`relative h-72 bg-cover bg-center bg-no-repeat cursor-pointer overflow-hidden group`} style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: backgroundSize }}>
            <div className="flex items-center justify-center h-full w-full bg-black bg-opacity-50 transition group-hover:bg-opacity-60">
                <div className="text-center transform transition-transform flex flex-col items-center justify-center relative">
                    <h1 className="text-2xl font-bold text-white transition-transform group-hover:-translate-y-4">{title}</h1>
                    {description && <p className="absolute text-white px-10 opacity-0 text-justify transition-transform group-hover:opacity-100 group-hover:relative group-hover:translate-y-4" dangerouslySetInnerHTML={{ __html: description }}></p>}
                </div>
            </div>
        </div>
    );
};

export default MenuItem;
