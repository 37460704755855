import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const defaultClass = "relative text-text-light transition-colors duration-300 ease-out hover:text-gray-300";
    const activeClass = "bg-primary text-accent-light";

    return (
        <div className="bg-primary h-20 fixed top-0 z-10 w-full flex items-center justify-between px-4 md:px-12 text-lg font-bold">
            <div className="text-white">
                Logo
            </div>
            <div className="hidden md:flex justify-between gap-10 p-4">
                <NavLink
                    to="/"
                    end
                    className={({ isActive }) =>
                        isActive ? activeClass : defaultClass
                    }
                >
                    Anasayfa
                </NavLink>
                <NavLink
                    to="/menu"
                    className={({ isActive }) =>
                        isActive ? activeClass : defaultClass
                    }
                >
                    Menü
                </NavLink>
            </div>
            <div className="text-white flex items-center md:hidden">
                <button onClick={toggleMenu}>
                    {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            </div>
            <div className="hidden md:flex">
                <FaInstagram className={`${defaultClass} cursor-pointer`} size={20} />
            </div>
            <div ref={menuRef} className={`fixed top-0 right-0 h-screen w-64 bg-primary transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:hidden`}>
                <div className="flex flex-col items-center justify-center h-full gap-4">
                    <NavLink
                        to="/"
                        end
                        className={({ isActive }) =>
                            isActive ? activeClass : defaultClass
                        }
                        onClick={toggleMenu}
                    >
                        Anasayfa
                    </NavLink>
                    <hr className="w-full border-t border-accent-light" />
                    <NavLink
                        to="/menu"
                        className={({ isActive }) =>
                            isActive ? activeClass : defaultClass
                        }
                        onClick={toggleMenu}
                    >
                        Menü
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
