import React from "react";

const Contact = () => {
    return (
        <div className="flex flex-col md:flex-row h-96">
            <div className="bg-primary text-white w-full md:w-1/2 p-8 flex flex-col justify-center items-center">
                <h2 className="text-4xl font-bold mb-4">Di Döner</h2>
                <p className="text-lg">Telefon: (123) 456-7890</p>
                <p className="text-lg">Adres: XXXX</p>
                

            </div>
            <div className="w-full md:w-1/2">
                <iframe
                    className="w-full h-full"
                    title="test"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.7323737665856!2d32.98424497594551!3d41.898612371239494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409ae799d033ba15%3A0x91f0bcbd955b0f31!2sDi%20D%C3%B6ner%20Cide!5e0!3m2!1str!2str!4v1718807712636!5m2!1str!2str"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
