import React from "react";
import LandingImage from '../assets/images/landing.jpg';
import { NavLink } from "react-router-dom";

const Home = () => {
    return (
        <div className="relative bg-red-200 flex justify-center items-center min-h-screen w-full">
            <img src={LandingImage} alt="Landing" className="absolute object-cover w-full h-full" />
            <div className="absolute gap-4 top-0 left-0 w-full h-full flex flex-col justify-center items-center text-center text-white bg-black bg-opacity-50 p-4">
                <h1 className="text-2xl sm:text-4xl font-bold mb-4">Soslu Dönerin En Lezzetli Hali!</h1>
                <p className="text-base sm:text-lg mb-6 w-full sm:w-3/5">
                    Di Döner olarak, en kaliteli malzemelerle hazırladığımız dönerlerimizi özel soslarımızla buluşturuyoruz. Her lokmada eşsiz bir lezzet deneyimi sunmak için buradayız. Afiyet olsun!
                </p>
                <NavLink to={"/menu"} className="bg-red-500 hover:bg-red-700 hover:transition-all transition-all text-white font-bold py-2 px-4 rounded">
                    Menümüzü İnceleyin
                </NavLink>
            </div>
        </div>
    )
}

export default Home;
